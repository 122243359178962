import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`About Me`}</h1>
    <p>{`Hi my name is Jimi. I fell in love with building software when I was 14 years old and am fortunate that I am able to do the thing that I love every day.`}</p>
    <p>{`In 2011, I built what is now known as the `}<a parentName="p" {...{
        "href": "http://www.pk4media.com"
      }}>{`Bishop Video Platform`}</a>{` while working full time as an Innovation Manager in the advertising industry. Although Bishop started as a side project, it generated seven figures in revenue its first year and was acquired by PK4 Media in 2012. After the acquisition I joined `}<a parentName="p" {...{
        "href": "http://www.pk4media.com"
      }}>{`PK4 Media`}</a>{` and served as Vice President of Product & Technology for two years. During my tenure I rebuilt the engineering team and most of the company's technology infrastructure before leaving to start another company.`}</p>
    <p>{`More recently, I founded the product studio `}<a parentName="p" {...{
        "href": "https://www.octaviuslabs.com"
      }}>{`Octavius Labs`}</a>{` to help companies grow by rethinking their product and technology strategy.`}</p>
    <p>{`In my spare time these days I like to pilot airplanes, `}<a parentName="p" {...{
        "href": "https://www.goodreads.com/review/list/30750463-jimi-smoot?utf8=%E2%9C%93&utf8=%E2%9C%93&shelf=read&per_page=infinite"
      }}>{`read`}</a>{`, and hang around Los Angeles with my wife, kids, and doggie son `}<a parentName="p" {...{
        "href": "http://www.instagram.com/jefecubano"
      }}>{`Alejandro`}</a>{`.`}</p>
    <p>{`Little known fact: During my time studying Marketing and Communications at the University of Nevada Las Vegas I worked as an Audio Engineer and Disc Jockey to pay the bills. I also somehow convinced the music school to give me a scholarship for Jazz Trombone and Marching Band.`}</p>
    <h2>{`Contact Jimi Smoot`}</h2>
    <p>{`Reach out at on `}<a parentName="p" {...{
        "href": "https://www.twitter.com/jsfour"
      }}>{`Twitter: @jsfour`}</a></p>
    <p><a parentName="p" {...{
        "href": "/public-key/"
      }}>{`Get My PGP Key Here`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      